<template>
  <div class="item-image-slider mb-4">
    <agile ref="main" :options="options" :as-nav-for="asNavFor1" class="main">
      <div
        v-for="(image, imageIndex) in images"
        :key="imageIndex"
        :class="`slide--${imageIndex}`"
        @click="galleryIndex = imageIndex"
        class="slide"
      >
        <div @click="galleryIndex = imageIndex" class="zoom-icon">
          <icon-search-plus />
        </div>
        <img :src="`${appURL}${image}`" />
      </div>

      <template slot="prevButton">
        <icon-chevron-left class="text-dark" />
      </template>
      <template slot="nextButton">
        <icon-chevron-right class="text-dark" />
      </template>
    </agile>
    <gallery
      :images="slides"
      :index="galleryIndex"
      @close="galleryIndex = null"
    ></gallery>
  </div>
</template>
<script>
import { VueAgile } from 'vue-agile'
import Gallery from 'vue-gallery'
import IconSearchPlus from '@/components/icons/IconSearchPlus'
import IconChevronRight from '@/components/icons/IconChevronRight'
import IconChevronLeft from '@/components/icons/IconChevronLeft'

export default {
  components: {
    IconSearchPlus,
    IconChevronRight,
    IconChevronLeft,
    agile: VueAgile,
    Gallery
  },
  props: {
    images: {
      required: true,
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data: function() {
    return {
      galleryIndex: null,
      asNavFor1: [],
      asNavFor2: [],
      options: {
        dots: false,
        fade: true,
        navButtons: false,
        responsive: [
          {
            breakpoint: 600
          },

          {
            breakpoint: 600,
            settings: {
              navButtons: true
            }
          }
        ]
      },
      options2: {
        autoplay: false,
        centerMode: true,
        dots: false,
        navButtons: false,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2
            }
          },

          {
            breakpoint: 600,
            settings: {
              navButtons: true
            }
          }
        ]
      }
    }
  },
  computed: {
    appURL: function() {
      return process.env.VUE_APP_URL
    },
    slides: function() {
      return this.images.map(image => `${this.appURL}${image}`)
    }
  },
  mounted() {
    this.asNavFor1.push(this.$refs.thumbnails)
    this.asNavFor2.push(this.$refs.main)
  }
}
</script>
<style lang="scss">
.zoom-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: white;
  z-index: 300;
  cursor: pointer;
  font-size: 24px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.zoom-icon:hover {
  color: #6c757d;
}

@media screen and (max-width: 1199px) {
  .zoom-icon {
    bottom: 5px;
    right: 12px;
  }
}
@media screen and (max-width: 991px) {
  .zoom-icon {
    bottom: 5px;
    right: 12px;
  }
}

@media screen and (max-width: 550px) {
  .zoom-icon {
    bottom: 5px;
    right: 20px;
  }
}

.slide {
  height: 100%;
}
.item-image-slider {
  max-height: 519px;
  height: 519px;
  border: 2px solid #f2f2f2;
}
.main {
  margin-bottom: 30px;
}

.thumbnails {
  margin: 0 -5px;
  width: calc(100% + 10px);
}

.slide.slide--0.agile__slide.agile__slide--current.agile__slide--active {
  height: 100% !important;
}
.agile {
  height: 100%;
}
.agile__list {
  height: 100%;
  width: auto !important;
}
.agile__slides {
  height: 100%;
}
.agile__track {
  height: 100%;
}
.agile__nav-button {
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 24px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.agile__nav-button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 30px;
}
.agile__nav-button--prev {
  left: 0px;
}
.agile__nav-button--next {
  right: 0px;
}
.agile__nav-button:hover {
  color: #888;
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button,
.agile__dot:hover button {
  background-color: #888;
}

.slide {
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}
.slide--thumbniail {
  cursor: pointer;
  height: 100px;
  padding: 0 5px;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.slide--thumbniail:hover {
  opacity: 0.75;
}
.slide img {
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}
</style>

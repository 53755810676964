<template>
  <span v-if="price > 0">
    &euro;
    {{
      (Math.round((price * amount + Number.EPSILON) * 100) / 100)
        .toFixed(2)
        .replace('.', ',')
    }}
  </span>
</template>

<script>
import { getItemPrice } from '@/services/ItemService'

export default {
  props: {
    startdate: {
      required: true,
      type: String
    },
    enddate: {
      required: true,
      type: String
    },
    warehouse: {
      required: true,
      type: String
    },
    amount: {
      required: true,
      type: Number
    },
    itemid: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      price: 0
    }
  },
  watch: {
    async warehouse() {
      await this.updateItemPrices()
    },
    async enddate() {
      await this.updateItemPrices()
    }
  },
  async created() {
    await this.updateItemPrices()
  },
  methods: {
    updateItemPrices: async function({
      itemID = this.itemid,
      startDate = this.startdate,
      endDate = this.enddate
    } = {}) {
      const result = await getItemPrice({ itemID, startDate, endDate })

      this.price = result.PriceIncVAT
    }
  }
}
</script>

<style></style>
